const createHeaderClickHandler = (
  header: HTMLElement,
  content: HTMLElement,
) => {
  let timeout: number | undefined;

  return () => {
    if (typeof timeout === "number") {
      window.clearTimeout(timeout);
    }

    if (content.style.height !== "0px") {
      content.style.height = `${content.scrollHeight}px`;
      header.classList.remove("open");

      timeout = window.setTimeout(() => {
        content.style.height = "0px";
      }, 50);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      header.classList.add("open");

      timeout = window.setTimeout(() => {
        content.style.height = "auto";
      }, 250);
    }
  };
};

const init = () => {
  const concertinas = [...document.querySelectorAll("[data-concertina=true]")];

  concertinas.forEach((concertina) => {
    const header = concertina.querySelector("[data-concertina-header=true]");
    const content = concertina.querySelector("[data-concertina-content=true]");

    if (header instanceof HTMLElement && content instanceof HTMLElement) {
      content.style.height = "0px";
      const onClickHeader = createHeaderClickHandler(header, content);
      header.addEventListener("click", onClickHeader);
    }
  });
};

init();
